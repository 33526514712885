<template>
  <div>
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Event Solutions</h1>
      <p>
        Use the Sodisp Challenge and Results Platform to create connected and engaging endurance sport events. Some of our key solutions are:
      </p>
    </div>

    <div class="white--text pb-6 pb-md-12">
      <div class="containerZ">
        <v-row >
          <v-col cols="12" md="6">
            <v-card :color="$helpers.getGradientColor(0)" :to="{name: 'solutionsRaceResults'}">
              <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-flag-checkered</v-icon></v-card-text>
              <v-card-title class="headline">Race Results</v-card-title>
              <v-card-text>
                Publish in-person race results to our fully customizable app and web platform.
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{name: 'solutionsRaceResults'}">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :color="$helpers.getGradientColor(1)" :to="{name: 'solutionsHybridEvents'}">
              <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-trophy</v-icon></v-card-text>
              <v-card-title class="headline">Hybrid Events</v-card-title>
              <v-card-text>
                You wish you could have everyone at your event, now you can bring your event to everyone! Full-Service and Self-Service packages available.
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{name: 'solutionsHybridEvents'}">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :color="$helpers.getGradientColor(2)" :to="{name: 'solutionsCorporateChallenges'}">
              <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-users</v-icon></v-card-text>
              <v-card-title class="headline">Corporate Challenges</v-card-title>
              <v-card-text>
                Use the Sodisp Challenge Platform to create motivating challenges to keep your employees fit and active.
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{name: 'solutionsCorporateChallenges'}">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :color="$helpers.getGradientColor(3)" :to="{name: 'solutionsYearRoundTrails'}">
              <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-route</v-icon></v-card-text>
              <v-card-title class="headline">OnCourse365: year-Round racing</v-card-title>
              <v-card-text>
                Take your on-course race and make it relevant 365 days a year! Allow participants to use their favorite app or GPS device to track their result.
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{name: 'solutionsYearRoundTrails'}">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :color="$helpers.getGradientColor(4)" :to="{name: 'solutionsWhiteLabel'}">
              <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-paint-roller</v-icon></v-card-text>
              <v-card-title class="headline">White-label app</v-card-title>
              <v-card-text>
                We can create a white-label app tailor-made for your event. Your branding, your app store account, your event app!
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{name: 'solutionsWhiteLabel'}">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :color="$helpers.getGradientColor(5)" :to="{name: 'solutionsEngine'}">
              <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-cogs</v-icon></v-card-text>
              <v-card-title class="headline">Fitness Engine</v-card-title>
              <v-card-text>
                Embed and connect with our fitness engine as a service to instantly add Fitbit, Garmin, Polar, and Apple Watch integrations to any product! 
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{name: 'solutionsEngine'}">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :color="$helpers.getGradientColor(6)" :to="{name: 'get'}">
              <v-card-text class="pb-0"><v-icon size="100">fadl fal fa-mobile</v-icon></v-card-text>
              <v-card-title class="headline">iPhone and Android app</v-card-title>
              <v-card-text>
                Allow your participants to use our app to follow your event and check progress and results.
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{name: 'get'}">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Use sodisp to create engaging and beautiful experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your challenge</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import siteData from '@/data/site.json'

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>